<template>
    <div id="assignedToYou" :class="{isFilterOpen: checkFilterOpen}">
        <mds-layout-grid>
            <mds-row class="main-screen-section">
                <div
                    class="leftBlock"
                    :class="{ showFilterLeftBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div>
                        <h2 class="assigned-to-you-header">Assigned to You</h2>
                    </div>
                    <div class="assigned-items-container">
                        <span class="assigned-items-count">
                            <span>You have </span>
                            <span v-if="totalItems >= 0">
                                <strong>{{ totalItems + " items " }}</strong>
                            </span>
                            <span>assigned to you.</span>
                        </span>
                    </div>

                    <div class="assigned-to-you-table-container">
                        <div class="data-table" v-if="taskList.rows.length > 0">
                            <mds-table row-hover show-sortable>
                                <mds-thead>
                                    <mds-th
                                        :style="{ width: header.width }"
                                        v-for="(header, index) in tableHeaders"
                                        :key="index"
                                        :sortable="header.sortable"
                                        :sorted="header.sorted"
                                        @mds-th-sort-changed="
                                            handleSortEvent(
                                                index,
                                                header.fieldName,
                                                $event
                                            )
                                        "
                                    >
                                        {{ header.text }}
                                    </mds-th>
                                </mds-thead>
                                <mds-tbody>
                                    <mds-tr
                                        v-for="(row, index) in taskList.rows"
                                        :key="index"
                                    >
                                        <mds-td
                                            v-for="(header, i) in tableHeaders"
                                            :key="i"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'Workflow'
                                                "
                                            >
                                                <span
                                                    class="no-highlight"
                                                    v-if="
                                                        row['editmode'] ===
                                                        'true'
                                                    "
                                                    @click="
                                                        redirectToAddInvestmentScreen(
                                                            row
                                                        )
                                                    "
                                                >
                                                    {{ row[header.fieldName] }}
                                                </span>
                                                <span
                                                    class="no-highlight"
                                                    v-if="
                                                        row['editmode'] ===
                                                        'false'
                                                    "
                                                    @click="
                                                        redirectToApproveScreen(
                                                            row
                                                        )
                                                    "
                                                >
                                                    {{ row[header.fieldName] }}
                                                </span>
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'Health'
                                                "
                                            >
                                                <span
                                                    class="health-status"
                                                    :style="{
                                                        height: '10px',
                                                        width: '10px',
                                                        'background-color':
                                                            colorCode(
                                                                row[
                                                                    header
                                                                        .fieldName
                                                                ]
                                                            ),
                                                        'border-radius': '50%',
                                                        display: 'inline-block',
                                                        'margin-right': '5px',
                                                    }"
                                                >
                                                </span>
                                                <span>
                                                    {{ row[header.fieldName] }}
                                                </span>
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName === 'Due'
                                                "
                                            >
                                                {{
                                                    convertDate(
                                                        row[header.fieldName]
                                                    )
                                                }}
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'UpdatedAt'
                                                "
                                            >
                                                {{
                                                    convertDate(
                                                        row[header.fieldName]
                                                    )
                                                }}
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                        'Step' ||
                                                    header.fieldName ===
                                                        'Entity' ||
                                                    header.fieldName ===
                                                        'UpdatedBy'
                                                "
                                            >
                                                {{ row[header.fieldName] }}
                                            </template>
                                        </mds-td>
                                    </mds-tr>
                                </mds-tbody>
                            </mds-table>
                        </div>
                        <mds-pagination
                            :class="[
                                showFilterPanel === true
                                    ? 'move-pagination-show-info'
                                    : '',
                                'pagination-component',
                            ]"
                            :key="paginationKey"
                            v-if="totalItems > 0"
                            show-items-info
                            show-items-select
                            :total-items="totalItems"
                            :pageSize="pageSize"
                            :pageSizes="[10, 20, 50]"
                            @mds-pagination-page-changed="paginateTable"
                        ></mds-pagination>
                        <mds-empty-state
                            v-if="totalItems <= 0"
                            class="no-results-message"
                            size="medium"
                            title="No results matched"
                            message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                        ></mds-empty-state>
                    </div>
                </div>

                <div
                    class="rightBlock"
                    :class="{ showFilterRightBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <filter-panel
                            searchInputPlaceHolder="Search by Entity name"
                            :key="filterKey"
                            :filterData="filterObject"
                            :isFilterPanelOpen="showFilterPanel"
                            :showSearchInput="true"
                            @dateRange="dateRange"
                            @multiSelectFilterChange="multiSelectFilterChange"
                            @clearInputFilter="onSearchInputFieldClear"
                            @clearAllFilters="clearAllFilters"
                            @filterEvent="searchTextFilterEvent"
                            @hideFilterPanel="closeFilterPanel"
                            @showFilterPanel="openFilterPannel"
                        ></filter-panel>
                    </div>
                </div>
            </mds-row>
        </mds-layout-grid>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
        ></notification-component>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FilterPanel from "../common_components/FilterPanel.vue";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import get from "lodash/get";
import { mapState } from "vuex";
import { TASK_ACTIONS } from "@/store/modules/tasks/contants";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import MdsPagination from "@mds/pagination";
import { dateFormatterMixin } from "../../mixins/date.js";
import MdsEmptyState from "@mds/empty-state";
import { REPORTING_ACTIONS } from "@/store/modules/reporting/constants";

export default {
    components: {
        MdsLayoutGrid,
        MdsRow,
        FilterPanel,
        NotificationComponent,
        LoaderComponent,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsPagination,
        MdsEmptyState,
    },

    mixins: [dateFormatterMixin],

    data() {
        return {
            showFilterPanel: false,
            isFilterPanelOpen: true,
            filterKey: 0,
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            showLoader: false,
            checkFilterOpen: false,
            page: 1,
            pageSize: 10,
            totalItems: 0,
            sortOrderColumnName: "UpdatedAt",
            sortOrderIsAscending: false,
            sortColIndex: 4,
            sortOrder: -1,
            taskList: {
                rows: [],
            },

            selectedUpdatedFromDate: "",
            selectedUpdatedToDate: "",

            selectedDueFromDate: "",
            selectedDueToDate: "",

            searchTextField: "",

            paginatedRows: null,
            paginationKey: 10000000,

            tableHeaders: [
                {
                    fieldName: "Workflow",
                    text: "Workflow",
                    width: "14%",
                    sortable: true,
                },
                {
                    fieldName: "Step",
                    text: "Step",
                    width: "16%",
                    sortable: true,
                },
                {
                    fieldName: "Entity",
                    text: "Entity",
                    width: "16%",
                    sortable: true,
                },
                {
                    fieldName: "Due",
                    text: "Due (UTC)",
                    width: "10%",
                    sortable: true,
                },
                {
                    fieldName: "UpdatedAt",
                    text: "Updated (UTC)",
                    width: "10%",
                    sortable: true,
                    sorted: -1,
                },
                {
                    fieldName: "UpdatedBy",
                    text: "Updated By",
                    width: "18%",
                    sortable: true,
                },
                {
                    fieldName: "Health",
                    text: "Health",
                    width: "7%",
                    sortable: true,
                },
            ],

            initailFilterObject: [],

            filterObject: [
                {
                    filterHeader: "Workflow",
                    selectionType: "multiple",
                    showSearchInputField: false,
                    searchText: "",
                    filterList: [],
                },
                {
                    filterHeader: "Step",
                    selectionType: "multiple",
                    showSearchInputField: false,
                    searchText: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "Updated By",
                    selectionType: "multiple",
                    showSearchInputField: false,
                    searchText: "",
                    filterList: [],
                },
                {
                    filterHeader: "Health",
                    selectionType: "multiple",
                    showSearchInputField: false,
                    searchText: "",
                    filterList: [],
                },
            ],
            taskPayload: {
                Page: 1,
                PageSize: 10,
                orderByColumnName: "",
                isAscending: false,
                PaginationSearchParameters: [
                    {
                        SearchColumnNames: ["ObjectTypeId"],
                        ComparisionType: 0,
                        SearchValues: [],
                    },
                    {
                        SearchColumnNames: ["Step"],
                        ComparisionType: 0,
                        SearchValues: [],
                    },
                    {
                        SearchColumnNames: ["Entity"],
                        ComparisionType: 0,
                        SearchValues: [],
                    },
                    {
                        SearchColumnNames: ["Due"],
                        ComparisionType: 3,
                        SearchValues: [],
                    },
                    {
                        SearchColumnNames: ["UpdatedAt"],
                        ComparisionType: 3,
                        SearchValues: [],
                    },
                    {
                        SearchColumnNames: ["UpdatedBy"],
                        ComparisionType: 0,
                        SearchValues: [],
                    },
                    {
                        SearchColumnNames: ["Health"],
                        ComparisionType: 0,
                        SearchValues: [],
                    },
                ],
            },
        };
    },

    props: {
        menuwidth: {
            type: String,
        },
    },

    created() {
        if (this.$route.params.workflowObject !== undefined) {
            this.showFilterPanel = true;
        }
    },

    async mounted() {
        this.showLoader = true;
        if (this.$route.params) {
            if (
                this.$route.params.notificationMessage &&
                this.$route.params.notificationType
            ) {
                if (this.$route.params.showNotification == true) {
                    this.showNotification = this.$route.params.showNotification;
                }
            }

            if (this.$route.params.notificationMessage) {
                this.notificationMessage =
                    this.$route.params.notificationMessage;
            }
            if (this.$route.params.notificationType) {
                this.notificationType = this.$route.params.notificationType;
            }
        }

        if (this.$route.params.navigatedFrom === "/approve") {
            this.pageSize = this.getPageSize;
        } else {
            this.pageSize = 10;
        }

        await this.fetchWorkflowAllUsersList();
        await this.fetchWorkflowStepList();
        await this.fetchWorkFlowList();
        await this.getDueDateRange();
        await this.getUpdatedDateRange();
        this.fetchWorkflowHealth();
        if (this.$route.params.workflowObject !== undefined) {
            this.filterObject[0].filterList.forEach(async (item) => {
                if (
                    item.lable === this.$route.params.workflowObject.objectLabel
                ) {
                    item.isChecked = true;

                    await this.multiSelectFilterChange(true, item, "Workflow");
                }
            });
        } else {
            await this.taskListFetch();
            this.showLoader = false;
        }
        this.initailFilterObject = this.filterObject;
    },

    watch: {
        selectedUpdatedFromDate() {
            this.onUpdatedFromDateChange();
        },

        selectedUpdatedToDate() {
            this.onUpdatedToDateChange();
        },

        selectedDueFromDate() {
            this.onDueFromDateChange();
        },

        selectedDueToDate() {
            this.onDueToDateChange();
        },

        showNotification: function () {
            setInterval(() => {
                this.showNotification = false;
            }, 3000);
        },
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapGetters("assignedToYou", ["getAssignedItems", "getPageSize"]),

        ...mapState({
            taskWorkFlowList(state) {
                return get(state, "tasks.filterWorkflow.filterWorkflow", []);
            },
            taskWorkFlowListLoader(state) {
                return get(state, "tasks.filterWorkflow.__loading__", false);
            },
            taskWorkFlowListError(state) {
                return get(state, "tasks.filterWorkflow.__error__", null);
            },
            taskWorkFlowStepList(state) {
                return get(
                    state,
                    "tasks.completedTaskWorkFlowSteps.completedTaskWorkFlowSteps",
                    []
                );
            },
            taskWorkFlowStepListLoader(state) {
                return get(
                    state,
                    "tasks.completedTaskWorkFlowSteps.__loading__",
                    false
                );
            },
            taskWorkFlowStepListError(state) {
                return get(
                    state,
                    "tasks.completedTaskWorkFlowSteps.__error__",
                    null
                );
            },
            getAllUserList(state) {
                return get(state, "reportingModule.userList.userList", []);
            },
        }),

        // to update changes in menu width
        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },
    },

    methods: {
        ...mapActions("assignedToYou", ["setAssignedItems"]),
        ...mapMutations("assignedToYou", ["setPageSize"]),

        redirectToApproveScreen(value) {
            this.$router.push(`/approve?id=${value.id}`);
        },
        redirectToAddInvestmentScreen(value) {
            this.$router.push(
                `/data-management/add-investment?entityid=${
                    value.entityid
                }&taskId=${value.id}&isEditable=${true}`
            );
            
        },

        getDueDateRange() {
            this.filterObject[3].filterHeader = "Due Date Range";
            this.filterObject[3].selectionType = "daterange";
            const selctedFromDate = this.selectedDueFromDate
                ? new Date(this.selectedDueFromDate)
                : this.selectedDueFromDate;
            const selctedToDate = this.selectedDueToDate
                ? new Date(this.selectedDueToDate)
                : this.selectedDueToDate;

            this.filterObject[3].filterList = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "from date",
                    value: selctedFromDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
                {
                    id: 2,
                    isdisabled: false,
                    lable: "to date",
                    value: selctedToDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
            ];
        },

        getUpdatedDateRange() {
            this.filterObject[2].filterHeader = "Updated Date Range";
            this.filterObject[2].selectionType = "daterange";
            const selctedFromDate = this.selectedUpdatedFromDate
                ? new Date(this.selectedUpdatedFromDate)
                : this.selectedUpdatedFromDate;
            const selctedToDate = this.selectedUpdatedToDate
                ? new Date(this.selectedUpdatedToDate)
                : this.selectedUpdatedToDate;

            this.filterObject[2].filterList = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "from date",
                    value: selctedFromDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
                {
                    id: 2,
                    isdisabled: false,
                    lable: "to date",
                    value: selctedToDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
            ];
        },

        // common function to fetch data
        async taskListFetch() {
            let data = this.taskPayload;

            data.Page = this.page;
            data.PageSize = this.pageSize;
            data.orderByColumnName = this.sortOrderColumnName;
            data.isAscending = this.sortOrderIsAscending;

            if (this.selectedUpdatedFromDate && this.selectedUpdatedToDate) {
                for (
                    let i = 0;
                    i < data.PaginationSearchParameters.length;
                    i++
                ) {
                    if (
                        data.PaginationSearchParameters[i]
                            .SearchColumnNames[0] === "UpdatedAt"
                    ) {
                        let temp = this.selectedUpdatedFromDate.split("/");
                        if (temp[0].length == 1) {
                            temp[0] = "0" + temp[0];
                        }
                        if (temp[1].length == 1) {
                            temp[1] = "0" + temp[1];
                        }
                        let fromdate = temp[2] + "-" + temp[0] + "-" + temp[1];

                        let temp1 = this.selectedUpdatedToDate.split("/");
                        if (temp1[0].length == 1) {
                            temp1[0] = "0" + temp1[0];
                        }
                        if (temp1[1].length == 1) {
                            temp1[1] = "0" + temp1[1];
                        }
                        let todate = temp1[2] + "-" + temp1[0] + "-" + temp1[1];

                        data.PaginationSearchParameters[i].SearchValues = [];
                        data.PaginationSearchParameters[i].SearchValues.push(
                            fromdate + "T00:00:00.000"
                        );
                        data.PaginationSearchParameters[i].SearchValues.push(
                            todate + "T23:59:59.000"
                        );
                    }
                }
            }

            if (this.selectedDueFromDate && this.selectedDueToDate) {
                for (
                    let i = 0;
                    i < data.PaginationSearchParameters.length;
                    i++
                ) {
                    if (
                        data.PaginationSearchParameters[i]
                            .SearchColumnNames[0] === "Due"
                    ) {
                        let temp = this.selectedDueFromDate.split("/");
                        if (temp[0].length == 1) {
                            temp[0] = "0" + temp[0];
                        }
                        if (temp[1].length == 1) {
                            temp[1] = "0" + temp[1];
                        }
                        let fromdate = temp[2] + "-" + temp[0] + "-" + temp[1];

                        let temp1 = this.selectedDueToDate.split("/");
                        if (temp1[0].length == 1) {
                            temp1[0] = "0" + temp1[0];
                        }
                        if (temp1[1].length == 1) {
                            temp1[1] = "0" + temp1[1];
                        }
                        let todate = temp1[2] + "-" + temp1[0] + "-" + temp1[1];

                        data.PaginationSearchParameters[i].SearchValues = [];
                        data.PaginationSearchParameters[i].SearchValues.push(
                            fromdate + "T00:00:00.000"
                        );
                        data.PaginationSearchParameters[i].SearchValues.push(
                            todate + "T23:59:59.000"
                        );
                    }
                }
            }

            if (this.searchTextField) {
                for (
                    let i = 0;
                    i < data.PaginationSearchParameters.length;
                    i++
                ) {
                    if (
                        data.PaginationSearchParameters[i]
                            .SearchColumnNames[0] === "Entity"
                    ) {
                        data.PaginationSearchParameters[i].SearchValues = [];
                        data.PaginationSearchParameters[i].SearchValues.push(
                            this.searchTextField
                        );
                        data.PaginationSearchParameters[i].ComparisionType = 1;
                    }
                }
            }

            await this.setAssignedItems(this.taskPayload);

            let response = await this.getAssignedItems;
            this.taskList = {
                rows: [],
            };

            if (response.isError !== true) {
                this.totalItems = response.data.totalItems;
                response.data.list.forEach((item) => {
                    let data = JSON.parse(item.objectdetail);
                    let modifiedObject = {};
                    modifiedObject.id = item.id;
                    modifiedObject.Workflow = data.Workflow;
                    modifiedObject.Step = data.Step;
                    modifiedObject.Entity = data.Entity;
                    modifiedObject.Due = item.duedate;
                    modifiedObject.UpdatedAt = data.Updated.replace(/"/g, "");
                    modifiedObject.UpdatedBy = data.UpdatedBy;
                    modifiedObject.Health = item.health;
                    for (const item in modifiedObject) {
                        modifiedObject[item] =
                            modifiedObject[item] === "" ||
                            modifiedObject[item] === null
                                ? "\u2014"
                                : modifiedObject[item];
                    }

                    modifiedObject.editmode = data.editmode;
                    modifiedObject.editurl = data.editurl;
                    modifiedObject.entityid = data.entityid;

                    this.taskList.rows.push(modifiedObject);
                });
            }
        },

        // updating newly selected dates from filter panel into filter object
        onUpdatedFromDateChange() {
            for (let i = 0; i < this.filterObject.length; i++) {
                if (
                    this.filterObject[i].filterHeader === "Updated Date Range"
                ) {
                    const selctedFromDate = this.selectedUpdatedFromDate
                        ? new Date(this.selectedUpdatedFromDate)
                        : this.selectedUpdatedFromDate;

                    this.filterObject[i].filterList[0].value = selctedFromDate;
                    // this.filterObject[i].filterList[1].mindate=this.selectedUpdatedFromDate;
                }
            }
        },

        onUpdatedToDateChange() {
            for (let i = 0; i < this.filterObject.length; i++) {
                if (
                    this.filterObject[i].filterHeader === "Updated Date Range"
                ) {
                    const selctedToDate = this.selectedUpdatedToDate
                        ? new Date(this.selectedUpdatedToDate)
                        : this.selectedUpdatedToDate;
                    this.filterObject[i].filterList[1].value = selctedToDate;
                    // this.filterObject[i].filterList[0].maxdate=this.selectedUpdatedToDate;
                }
            }
        },

        onDueFromDateChange() {
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader === "Due Date Range") {
                    const selctedFromDate = this.selectedDueFromDate
                        ? new Date(this.selectedDueFromDate)
                        : this.selectedDueFromDate;
                    this.filterObject[i].filterList[0].value = selctedFromDate;
                    // this.filterObject[i].filterList[1].mindate=this.selectedDueFromDate;
                }
            }
        },

        onDueToDateChange() {
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader === "Due Date Range") {
                    const selctedToDate = this.selectedDueToDate
                        ? new Date(this.selectedDueToDate)
                        : this.selectedDueToDate;
                    this.filterObject[i].filterList[1].value = selctedToDate;
                    // this.filterObject[i].filterList[0].maxdate=this.selectedDueToDate;
                }
            }
        },

        // api calls for filter panel
        async fetchWorkFlowList() {
            await this.cachedDispatch(TASK_ACTIONS.FETCH_FILTER_WORKFLOW_LIST);
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader === "Workflow") {
                    for (let j = 0; j < this.taskWorkFlowList.length; j++) {
                        let workFlowObject = {
                            lable: this.taskWorkFlowList[j].objectlabel,
                            id: this.taskWorkFlowList[j].id,
                            isChecked: false,
                        };
                        this.filterObject[i].filterList.push(workFlowObject);
                    }
                }
            }
        },

        async fetchWorkflowStepList() {
            await this.cachedDispatch(
                TASK_ACTIONS.FETCH_COMPLETEDTASK_WORKFLOW_STEP
            );
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader === "Step") {
                    for (let j = 0; j < this.taskWorkFlowStepList.length; j++) {
                        let workFlowObject = {
                            lable: this.taskWorkFlowStepList[j].stepName,
                            id: j,
                            isChecked: false,
                        };
                        this.filterObject[i].filterList.push(workFlowObject);
                    }
                }
            }
        },

        async fetchWorkflowAllUsersList() {
            await this.cachedDispatch(REPORTING_ACTIONS.FETCH_USER_LIST);
            let response = this.getAllUserList.data;
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader === "Updated By") {
                    for (let j = 0; j < response.length; j++) {
                        let workFlowObject = {
                            lable: response[j].email,
                            id: j,
                            isChecked: false,
                        };
                        this.filterObject[i].filterList.push(workFlowObject);
                    }
                }
            }
        },

        fetchWorkflowHealth() {
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader === "Health") {
                    this.filterObject[i].filterList.push(
                        {
                            id: 1,
                            isChecked: false,
                            lable: "On Track",
                        },
                        {
                            id: 2,
                            isChecked: false,
                            lable: "Due Soon",
                        },
                        {
                            id: 3,
                            isChecked: false,
                            lable: "Overdue",
                        }
                    );
                }
            }
        },

        // emitted event on date range change
        async dateRange(fromDate, toDate, type, filterDataDetails) {
            // this line is added so that when we change the dates, the other parts of filter donot get cleared or updated.
            this.filterObject = filterDataDetails;
            this.page = 1;
            this.paginationKey++;
            if (type === "Updated Date Range") {
                this.selectedUpdatedFromDate = fromDate.toLocaleDateString();
                this.selectedUpdatedToDate = toDate.toLocaleDateString();
                this.showLoader = true;
                await this.taskListFetch();
                this.showLoader = false;
            } else if (type === "Due Date Range") {
                this.selectedDueFromDate = fromDate.toLocaleDateString();
                this.selectedDueToDate = toDate.toLocaleDateString();
                this.showLoader = true;
                await this.taskListFetch();
                this.showLoader = false;
            }
        },

        // emitted event on search text box inside filter panel
        async searchTextFilterEvent(searchValue) {
            if (searchValue) {
                this.searchTextField = searchValue;
                this.showLoader = true;
                this.page = 1;
                await this.taskListFetch();
                this.paginationKey++;
                this.showLoader = false;
            }
        },

        // emitted event on multiple filter checkbox select
        async multiSelectFilterChange(event, value, headerTypeDetails) {
            let header = "";
            // logic to move selected checkboxes to top in the list
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (
                        let j = 0;
                        j < this.filterObject[i].filterList.length;
                        j++
                    ) {
                        if (this.filterObject[i].filterList[j].id == value.id) {
                            this.filterObject[i].filterList[j].isChecked =
                                event;
                            break;
                        }
                    }
                }
            }
            if (headerTypeDetails === "Workflow") {
                header = "ObjectTypeId";
            } else if (headerTypeDetails === "Step") {
                header = "Step";
            } else if (headerTypeDetails === "Updated By") {
                header = "UpdatedBy";
            } else if (headerTypeDetails === "Health") {
                header = "Health";
            }
            if (event) {
                for (
                    let i = 0;
                    i < this.taskPayload.PaginationSearchParameters.length;
                    i++
                ) {
                    if (
                        this.taskPayload.PaginationSearchParameters[
                            i
                        ].SearchColumnNames[0].toLowerCase() ==
                            header.toLowerCase() &&
                        header !== "ObjectTypeId"
                    ) {
                        this.taskPayload.PaginationSearchParameters[
                            i
                        ].SearchValues.push(value.lable);
                    } else if (
                        this.taskPayload.PaginationSearchParameters[
                            i
                        ].SearchColumnNames[0].toLowerCase() ==
                            header.toLowerCase() &&
                        header === "ObjectTypeId"
                    ) {
                        this.taskPayload.PaginationSearchParameters[
                            i
                        ].SearchValues.push(value.id);
                    }
                }
            } else {
                for (
                    let i = 0;
                    i < this.taskPayload.PaginationSearchParameters.length;
                    i++
                ) {
                    if (
                        this.taskPayload.PaginationSearchParameters[
                            i
                        ].SearchColumnNames[0].toLowerCase() ==
                        header.toLowerCase()
                    ) {
                        if (
                            this.taskPayload.PaginationSearchParameters[i]
                                .SearchValues.length != 0
                        ) {
                            for (
                                let j = 0;
                                j <
                                this.taskPayload.PaginationSearchParameters[i]
                                    .SearchValues.length;
                                j++
                            ) {
                                if (header !== "ObjectTypeId") {
                                    if (
                                        this.taskPayload.PaginationSearchParameters[
                                            i
                                        ].SearchValues[j].toLowerCase() ==
                                        value.lable.toLowerCase()
                                    ) {
                                        this.taskPayload.PaginationSearchParameters[
                                            i
                                        ].SearchValues.splice(j, 1);
                                        break;
                                    }
                                } else if (header === "ObjectTypeId") {
                                    if (
                                        this.taskPayload
                                            .PaginationSearchParameters[i]
                                            .SearchValues[j] == value.id
                                    ) {
                                        this.taskPayload.PaginationSearchParameters[
                                            i
                                        ].SearchValues.splice(j, 1);
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.page = 1;
            this.showLoader = true;
            this.paginationKey++;
            await this.taskListFetch();
            this.showLoader = false;
        },

        async clearAllFilters() {
            this.selectedUpdatedFromDate = "";
            this.selectedUpdatedToDate = "";
            this.selectedDueFromDate = "";
            this.selectedDueToDate = "";
            this.searchTextField = "";
            for (
                let i = 0;
                i < this.taskPayload.PaginationSearchParameters.length;
                i++
            ) {
                this.taskPayload.PaginationSearchParameters[i].SearchValues =
                    [];
                // this.taskPayload.PaginationSearchParameters[i].ComparisionType = 0;
            }
            this.filterObject = this.initailFilterObject;
            this.filterKey++;
            this.page = 1;
            this.paginationKey++;
            this.showLoader = true;
            await this.taskListFetch();
            this.showLoader = false;
        },

        async onSearchInputFieldClear() {
            for (
                let i = 0;
                i < this.taskPayload.PaginationSearchParameters.length;
                i++
            ) {
                if (
                    this.taskPayload.PaginationSearchParameters[i]
                        .SearchColumnNames[0] === "Entity"
                ) {
                    this.taskPayload.PaginationSearchParameters[
                        i
                    ].SearchValues = [];
                }
                // this.taskPayload.PaginationSearchParameters[i].ComparisionType = 0;
            }
            this.searchTextField = "";
            this.showLoader = true;
            this.page = 1;
            await this.taskListFetch();
            this.paginationKey++;
            this.showLoader = false;
        },

        closeFilterPanel() {
            this.checkFilterOpen = false;
            this.showFilterPanel = false;
        },

        openFilterPannel() {
            this.checkFilterOpen = true;
            this.showFilterPanel = true;
        },

        getRouteDetails(url) {
            let routeDetails;
            if (url == "/addinvestment") {
                routeDetails = this.$router.resolve({
                    path: "/data-management/add-investment",
                });
            } else {
                routeDetails = this.$router.resolve({
                    path: url,
                });
            }
            return routeDetails.resolved.name;
        },

        colorCode(healthStatus) {
            if (healthStatus === "On Track" || healthStatus === "Good") {
                return "#00AF41";
            } else if (healthStatus === "Due Soon" || healthStatus === "Fair") {
                return "#F5C400";
            } else {
                return "#FF0000";
            }
        },

        async paginateTable(arg) {
            this.showLoader = true;
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.setPageSize(this.pageSize);
            await this.taskListFetch();
            this.showLoader = false;
        },

        async handleSortEvent(colIndex, colName) {
            this.showLoader = true;
            this.executeSortingLogicForMdsTable(colIndex);
            this.sortOrderColumnName = colName;
            this.sortOrder == 1
                ? (this.sortOrderIsAscending = true)
                : (this.sortOrderIsAscending = false);
            this.page = 1;
            await this.taskListFetch();
            this.paginationKey++;
            this.showLoader = false;
        },

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.tableHeaders[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.tableHeaders[colIndex], "sorted", this.sortOrder);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

.isFilterOpen {width: 82%}

html {
    @media screen and (min-width: 1280px) {
        overflow-x: hidden;
    }
}

.main-screen-section {
    margin: 0;
    padding: 0;
}

.assigned-to-you-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.assigned-to-you-table-container {
    margin-top: 13px;

    .data-table {
        height: auto;
        max-height: 72vh;
        overflow: auto;
    }
}

.leftBlock {
    width: 94%;
    position: relative;
    height: 100%;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

.move-cancel-save-button-container {
    margin-right: calc(292px - 55px);
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.link {
    @include mds-link($in-table: true);
}

.table-component {
    min-height: 520px;
    max-height: 520px;
    overflow: auto;
}

.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}

.no-highlight {
    color: black;
    cursor: pointer;
}
</style>
