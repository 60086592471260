var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{isFilterOpen: _vm.checkFilterOpen},attrs:{"id":"assignedToYou"}},[_c('mds-layout-grid',[_c('mds-row',{staticClass:"main-screen-section"},[_c('div',{staticClass:"leftBlock",class:{ showFilterLeftBlock: _vm.showFilterPanel },style:(_vm.cssVariables)},[_c('div',[_c('h2',{staticClass:"assigned-to-you-header"},[_vm._v("Assigned to You")])]),_c('div',{staticClass:"assigned-items-container"},[_c('span',{staticClass:"assigned-items-count"},[_c('span',[_vm._v("You have ")]),(_vm.totalItems >= 0)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.totalItems + " items "))])]):_vm._e(),_c('span',[_vm._v("assigned to you.")])])]),_c('div',{staticClass:"assigned-to-you-table-container"},[(_vm.taskList.rows.length > 0)?_c('div',{staticClass:"data-table"},[_c('mds-table',{attrs:{"row-hover":"","show-sortable":""}},[_c('mds-thead',_vm._l((_vm.tableHeaders),function(header,index){return _c('mds-th',{key:index,style:({ width: header.width }),attrs:{"sortable":header.sortable,"sorted":header.sorted},on:{"mds-th-sort-changed":function($event){return _vm.handleSortEvent(
                                            index,
                                            header.fieldName,
                                            $event
                                        )}}},[_vm._v(" "+_vm._s(header.text)+" ")])}),1),_c('mds-tbody',_vm._l((_vm.taskList.rows),function(row,index){return _c('mds-tr',{key:index},_vm._l((_vm.tableHeaders),function(header,i){return _c('mds-td',{key:i,attrs:{"right-aligned":header.align === 'right'}},[(
                                                header.fieldName ===
                                                'Workflow'
                                            )?[(
                                                    row['editmode'] ===
                                                    'true'
                                                )?_c('span',{staticClass:"no-highlight",on:{"click":function($event){return _vm.redirectToAddInvestmentScreen(
                                                        row
                                                    )}}},[_vm._v(" "+_vm._s(row[header.fieldName])+" ")]):_vm._e(),(
                                                    row['editmode'] ===
                                                    'false'
                                                )?_c('span',{staticClass:"no-highlight",on:{"click":function($event){return _vm.redirectToApproveScreen(
                                                        row
                                                    )}}},[_vm._v(" "+_vm._s(row[header.fieldName])+" ")]):_vm._e()]:_vm._e(),(
                                                header.fieldName ===
                                                'Health'
                                            )?[_c('span',{staticClass:"health-status",style:({
                                                    height: '10px',
                                                    width: '10px',
                                                    'background-color':
                                                        _vm.colorCode(
                                                            row[
                                                                header
                                                                    .fieldName
                                                            ]
                                                        ),
                                                    'border-radius': '50%',
                                                    display: 'inline-block',
                                                    'margin-right': '5px',
                                                })}),_c('span',[_vm._v(" "+_vm._s(row[header.fieldName])+" ")])]:_vm._e(),(
                                                header.fieldName === 'Due'
                                            )?[_vm._v(" "+_vm._s(_vm.convertDate( row[header.fieldName] ))+" ")]:_vm._e(),(
                                                header.fieldName ===
                                                'UpdatedAt'
                                            )?[_vm._v(" "+_vm._s(_vm.convertDate( row[header.fieldName] ))+" ")]:_vm._e(),(
                                                header.fieldName ===
                                                    'Step' ||
                                                header.fieldName ===
                                                    'Entity' ||
                                                header.fieldName ===
                                                    'UpdatedBy'
                                            )?[_vm._v(" "+_vm._s(row[header.fieldName])+" ")]:_vm._e()],2)}),1)}),1)],1)],1):_vm._e(),(_vm.totalItems > 0)?_c('mds-pagination',{key:_vm.paginationKey,class:[
                            _vm.showFilterPanel === true
                                ? 'move-pagination-show-info'
                                : '',
                            'pagination-component' ],attrs:{"show-items-info":"","show-items-select":"","total-items":_vm.totalItems,"pageSize":_vm.pageSize,"pageSizes":[10, 20, 50]},on:{"mds-pagination-page-changed":_vm.paginateTable}}):_vm._e(),(_vm.totalItems <= 0)?_c('mds-empty-state',{staticClass:"no-results-message",attrs:{"size":"medium","title":"No results matched","message":"We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."}}):_vm._e()],1)]),_c('div',{staticClass:"rightBlock",class:{ showFilterRightBlock: _vm.showFilterPanel },style:(_vm.cssVariables)},[_c('div',{staticClass:"filterPanel"},[_c('filter-panel',{key:_vm.filterKey,attrs:{"searchInputPlaceHolder":"Search by Entity name","filterData":_vm.filterObject,"isFilterPanelOpen":_vm.showFilterPanel,"showSearchInput":true},on:{"dateRange":_vm.dateRange,"multiSelectFilterChange":_vm.multiSelectFilterChange,"clearInputFilter":_vm.onSearchInputFieldClear,"clearAllFilters":_vm.clearAllFilters,"filterEvent":_vm.searchTextFilterEvent,"hideFilterPanel":_vm.closeFilterPanel,"showFilterPanel":_vm.openFilterPannel}})],1)])])],1),(_vm.showNotification)?_c('notification-component',{attrs:{"notificationMessage":_vm.notificationMessage,"notificationType":_vm.notificationType}}):_vm._e(),(_vm.showLoader)?_c('loader-component'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }